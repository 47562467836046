<template>
	<div class="main">
		<template  v-if="dataInfo">
			<div class="info-card-box">
				<div class="card-line-box">
					<span class="card-item-title">市场：</span>
					<span class="card-item-text">{{dataInfo.market}}</span>
				</div>
				<div class="card-line-box">
					<span class="card-item-title">部门：</span>
					<span class="card-item-text">{{dataInfo.depName}}</span>
				</div>
				<div class="card-line-box">
					<span class="card-item-title">岗位：</span>
					<span class="card-item-text">{{dataInfo.position}}</span>
				</div>
				<div class="card-line-box">
					<span class="card-item-title">姓名：</span>
					<span class="card-item-text">{{dataInfo.userName}}</span>
				</div>
			</div>
			<div class="perf-info-box">
				<div class="table-title-box">
					<img style="width: 15px;height: 15px" src="../../assets/image/performance/table.png"/>
					<span class="table-title-text">{{monthArr[0]}}年{{monthArr[1]}}月绩效考核表</span>
				</div>
				<div v-if="operateType === 0" class="table-box">
					<div class="table-title-line">
						<div class="table-cell" style="width: 40vw">指标名称</div>
						<div class="table-cell" style="width: 25vw">权重</div>
						<!--<div class="table-cell" style="width: 40vw">定义及计算方法</div>-->
						<div class="table-cell" style="width: 20vw">目标值</div>
					</div>
					<div v-for="(item, index) in listData.contents" :key="index" class="table-line">
						<div class="table-cell" style="width: 40vw">{{item.target}}</div>
						<div class="table-cell" style="width: 25vw">{{item.weight}}%</div>
						<!--<div class="table-cell" style="width: 40vw">{{item.computeMode}}</div>-->
						<div class="table-cell" style="width: 20vw">{{item.targetValue}}</div>
					</div>
				</div>
				<div v-if="operateType === 1" class="table-box">
					<div class="table-title-line">
						<div class="table-cell" style="width: 25vw">指标名称</div>
						<div class="table-cell" style="width: 10vw">权重</div>
						<!--<div class="table-cell" style="width: 40vw">定义及计算方法</div>-->
						<div class="table-cell" style="width: 15vw">目标值</div>
						<div class="table-cell" style="width: 20vw">自评得分</div>
						<div class="table-cell" style="width: 20vw">领导评分</div>
						<div class="table-cell" style="width: 20vw">总部人事评分</div>
					</div>
					<div v-for="(item, index) in listData.contents" :key="index" class="table-line">
						<div class="table-cell" style="width: 25vw">{{item.target}}</div>
						<div class="table-cell" style="width: 10vw">{{item.weight}}%</div>
						<!--<div class="table-cell" style="width: 40vw">{{item.computeMode}}</div>-->
						<div class="table-cell" style="width: 15vw">{{item.targetValue}}</div>
						<div class="table-cell" style="width: 20vw">{{item.selfRatingScore}}</div>
						<div class="table-cell" style="width: 20vw">{{item.leaderRatingScore}}</div>
						<div class="table-cell" style="width: 20vw">{{item.hrRatingScore}}</div>
					</div>
					<div class="table-line">
						<div class="table-cell" style="width: 25vw">合计</div>
						<div class="table-cell" style="width: 10vw">100%</div>
						<!--<div class="table-cell" style="width: 40vw">定义及计算方法</div>-->
						<div class="table-cell" style="width: 15vw"></div>
						<div class="table-cell" style="width: 20vw">{{totalInfo.selfTotal}}</div>
						<div class="table-cell" style="width: 20vw">{{totalInfo.leaderTotal}}</div>
						<div class="table-cell" style="width: 20vw">{{totalInfo.hrTotal}}</div>
					</div>
				</div>
				<div @click="toAddInfo" class="add-button">
					<img style="width: 15px;height: 15px;padding-right: 5px" src="../../assets/image/performance/show.png"/>
					<span class="add-button-text">查看考核详情</span>
				</div>
				<div>
					<div class="ticket-title">附件</div>
					<div class="no-text">
						<van-uploader upload-icon="https://tousubiaoyang-1301970825.file.myqcloud.com/chartVideo/165994288232878081" v-model="ticket" :max-count="3" />
					</div>
				</div>
				<div v-if="operateType === 1">
					<div class="add-branch-box">
						<div>领导加减分：</div>
						<div v-if="dataInfo.supSubtract > 0">加分{{dataInfo.supSubtract}}</div>
						<div v-else-if="dataInfo.supSubtract < 0">减分{{dataInfo.supSubtract}}</div>
						<div v-else>未加减分</div>
					</div>
					<div class="add-branch-box">
						<div>人事加减分：</div>
						<div v-if="dataInfo.hrSubtract > 0">加分{{dataInfo.hrSubtract}}</div>
						<div v-else-if="dataInfo.hrSubtract < 0">减分{{dataInfo.hrSubtract}}</div>
						<div v-else>未加减分</div>
					</div>
					<div class="add-branch-box">
						<div>最终得分：</div>
						<div>{{dataInfo.finalScore}}</div>
					</div>
				</div>
			</div>
			<div class="perf-info-box">
				<div class="order-status-title">审批流程</div>
				<div>
					<van-steps direction="vertical" active-icon="https://tousubiaoyang-1301970825.file.myqcloud.com/chartVideo/165978345841926566" inactive-icon="https://tousubiaoyang-1301970825.file.myqcloud.com/chartVideo/165978345841926566" active-color="#333333" :active="stepId">
						<van-step v-for="(item, index) in activities" :key="index">
							<p class="steps-title">{{item.levelName}}<span v-if="index === 0" class="active-tip">或签</span></p>
							<div class="leader-box">
								<div v-for="(item2,index2) in item.auditorList" :key="index2" class="leader-item">
									<div class="leader-item-box">
										<div class="leader-item-name-box">
											<div class="leader-item-header-img">
												<img v-if="item2.checkFlag>0" class="step-ok-img" src="../../assets/image/performance/ok.png"/>
												<img class="step-img" src="../../assets/image/performance/r.png"/>
											</div>
											<span>{{item2.auditorName}}</span>
										</div>
										<div v-if="item2.checkFlag>0" class="leader-item-time">
											<span class="leader-ok" v-if="item2.checkFlag === 1">已完成</span>
											<span class="leader-no" v-if="item2.checkFlag === 2">已退回</span>
											<span>{{item2.createTime}}</span>
										</div>
									</div>
									<div v-if="item2.checkFlag === 2" class="back-msg">退回原因：{{item2.message}}</div>
								</div>
							</div>
						</van-step>
					</van-steps>
				</div>
			</div>
			<div style="width: 100vw;height: 80px"></div>
			<!--<div class="bottom-button-box">
				<div @click="backIndex()" class="button-no">返回</div>
				<div @click="showOk()" class="button-ok">提交</div>
			</div>-->
			<div v-if="dataInfo.beginMonthStatus === 3"  class="bottom-button-box">
				<div @click="backIndex()" class="button-ok">重新填报</div>
			</div>
		</template>
		<template v-else>
			<empty description="暂无数据" />
		</template>
		<!--驳回弹窗开始-->
		<van-popup
				v-model="noShow"
				lock-scroll
				round
				:style="{ width:'80vw' }"
		>
			<div class="message-box-info-box">
				<div class="message-box-info-top-title">提示</div>
				<div class="message-box-info-top-info">确认审批退回本月绩效考核吗？</div>
				<div class="message-box-text">
					<van-field
							style="font-size: 16px;border: 1px solid #F1F1F1;margin: 0 20px 20px 20px"
							v-model="examplesData.rejectReason"
							rows="2"
							autosize
							type="textarea"
							placeholder="请输入退回原因"
					/>
				</div>
				<div class="message-box-button">
					<div @click="hideBox" class="button-cancel">取消</div>
					<div @click="toNo(3)" class="button-ok-popup">确认</div>
				</div>
			</div>
		</van-popup>
		<!--驳回弹窗结束-->
		<!--通过弹窗开始-->
		<van-popup
				v-model="okShow"
				lock-scroll
				round
				:style="{ width:'80vw' }"
		>
			<div class="message-box-info-box">
				<div class="message-box-info-top-title">提示</div>
				<div class="message-box-info-top-info">确认提交绩效考核得分吗？</div>
				<div class="message-box-button">
					<div @click="okShow = false" class="button-cancel">取消</div>
					<div @click="addPerfData(2)" class="button-ok-popup">确认</div>
				</div>
			</div>
		</van-popup>
		<!--通过弹窗结束-->
		<!--选择加减分开始-->
		<van-overlay :show="showOverlay" />
		<div v-show="showSelect" class="picker-box">
			<van-picker
					title="标题"
					show-toolbar
					:columns="qzOptions"
					@confirm="onConfirm"
					@cancel="onCancel"
			/>
		</div>
		<!--选择加减分结束-->
	</div>
</template>

<script>
	import { getPerfById } from '@/api/performance'
	import {Dialog} from 'vant';
	import { Empty } from 'vant';
	export default {
		name: 'DemandList',
		components: {
			Empty
		},
		data() {
			return {
				id: '',
				operateType: '', // 考核类型 1 2
				examplesData:{
					moduleId:'',
					rejectReason: '',
					status: '',
					type: ''
				},
				activities: [
					{
						message:'上级市场老总',
						operator:''
					},
					{
						message:'总部人事',
						operator:''
					},
				], // 流程列表
				dataInfo: {},
				listData: {
					id: '',
					submitType: '',
					modifyType: '',
					images: '',
					months: '',
					contents: [
						{
							id: '',
							assessId: '',
							computeMode: '',
							rater: '',
							raterName: '',
							remarks: '',
							source: '',
							target: '',
							targetValue: '',
							weight: ''
						}
					],
				},
				ticket:[],
				show: false,
				cos: {},
				month: '',
				monthArr: '',
				getStatus: '', // 判断是通过通知进来  还是通过列表进来
				noShow: false, // 驳回弹窗
				okShow: false, // 通过弹窗
				showSelect: false, // 是否展示选择权重
				showOverlay: false, // 是否显示遮罩
				qzOptions: ['加分','减分'],
				postData: {
					assessId:'', //评分id
					hrSubtract: '', // hr加减分
					supSubtract:'', // 上级领导加减分
					contents:[
						{
							id:'',
							selfEval:'', // 自评
							selfRatingScore:'', // 自评得分
							taskResponse:'', // 任务完成情况
							leaderRatingScore:'', // 领导评分
							leaderEval:'', // 领导评语
							hrRatingScore:'', // hr评分
							hrEval:'' // hr评语
						}
					]
				},
				branchType: '加分', // 加减分类型
				stepId: 0, // 判断进行到哪一步
				totalInfo: {
					selfTotal: 0,
					leaderTotal: 0,
					hrTotal: 0,
				}
			}
		},
		created() {
			const query = this.$route.query
			if (!query.id) {
				Dialog.alert({title: '提示', message: '参数错误',})
				return
			}
			// 判断是否登录
			if(!sessionStorage.getItem("token")){
				//存储路由
				sessionStorage.setItem("perfPath", this.$route.path)
				sessionStorage.setItem("perfQuery", JSON.stringify(query))
				const postData = {}
				this.$router.push({ path: '/perfLogin', query: postData})
				return
			}
			this.id = query.id
			this.getInfo()
			// this.showApproval()
			// this.initCOS()
		},
		mounted:function(){
		},
		methods: {
			// 展示选择
			showSelectType(){
				this.showSelect = true
				this.showOverlay = true
			},
			// 确认选择权重
			onConfirm(value, index) {
				console.log(value)
				console.log(index)
				this.showSelect = false
				this.showOverlay = false
				this.branchType = value
			},
			// 点击取消
			onCancel() {
				this.showSelect = false
				this.showOverlay = false
			},
			// 获取信息
			getInfo(){
				const postData = {}
				postData.id = this.id
				getPerfById(postData).then((res) => {
					// 如果不在审批流范围 增加错误提示
					if (res.data.level === 3) {
						Dialog.alert({title: '提示', message: '您没有权限查看此任务！',})
						return false
					}
					this.dataInfo = res.data.assessForm
					this.operateType = res.data.operateType
					this.activities = res.data.list
					this.month = res.data.assessForm.years
					this.monthArr = res.data.assessForm.years.split("-")
					this.listData.contents = res.data.detailFormList
					this.listData.id = res.data.assessForm.id
					this.postData.contents = []
					// 初始化数据
					for(let i = 0; i < res.data.detailFormList.length; i++) {
						let tempData = {
							id:'',
							selfEval:'', // 自评
							selfRatingScore:'', // 自评得分
							taskResponse:'', // 任务完成情况
							leaderRatingScore:'', // 领导评分
							leaderEval:'', // 领导评语
							hrRatingScore:'', // hr评分
							hrEval:'' // hr评语
						}
						tempData.id = res.data.detailFormList[i].id
						tempData.selfEval = res.data.detailFormList[i].selfEval
						tempData.selfRatingScore = res.data.detailFormList[i].selfRatingScore
						tempData.taskResponse = res.data.detailFormList[i].taskResponse
						tempData.leaderRatingScore = res.data.detailFormList[i].leaderRatingScore
						tempData.leaderEval = res.data.detailFormList[i].leaderEval
						tempData.hrRatingScore = res.data.detailFormList[i].hrRatingScore
						tempData.hrEval = res.data.detailFormList[i].hrEval
						this.postData.contents.push(tempData)
						this.totalInfo.selfTotal = Number(this.totalInfo.selfTotal) + Number(res.data.detailFormList[i].weight) * Number(res.data.detailFormList[i].selfRatingScore)
						this.totalInfo.leaderTotal = Number(this.totalInfo.leaderTotal) + Number(res.data.detailFormList[i].weight) * Number(res.data.detailFormList[i].leaderRatingScore)
						this.totalInfo.hrTotal = Number(this.totalInfo.hrTotal) + Number(res.data.detailFormList[i].weight) * Number(res.data.detailFormList[i].hrRatingScore)
					}
					this.totalInfo.selfTotal = (this.totalInfo.selfTotal/100).toFixed(2)
					this.totalInfo.leaderTotal = (this.totalInfo.leaderTotal/100).toFixed(2)
					this.totalInfo.hrTotal = (this.totalInfo.hrTotal/100).toFixed(2)
					// 判断进行到了哪一步
					for (let i = 0; i < this.activities.length; i++) {
						if(this.activities[i].checkFlag === 1 || this.activities[i].checkFlag === 2){
							this.stepId = i + 1
						}
					}
					// 格式化图片链接
					if(this.dataInfo.images !== '' && this.dataInfo.images !== undefined){
						const tempImg = JSON.parse(this.dataInfo.images)
						this.ticket = []
						for(let i = 0; i < tempImg.length; i++){
							let tempUrl = {}
							tempUrl.url = tempImg[i]
							tempUrl.isImage = true
							this.ticket.push(tempUrl)
						}
						console.log(this.ticket)
					}
				})
			},
			// 去查看详情
			toAddInfo(){
				const postData = {}
				postData.id = this.id
				postData.stepId = this.stepId
				postData.operateType = this.operateType
				this.$router.push({ path: '/showApproval', query: postData})
			},
			// 返回首页
			backIndex(){
				const postData = {}
				postData.month = this.month
				this.$router.push({ path: '/performance', query: postData})
			},
			// 展示退回弹窗
			returnData(){
				this.noShow = true
			},
			// 展示通过弹窗
			showOk(){
				this.okShow = true
			},
			// 隐藏弹窗
			hideBox(){
				this.noShow = false
				this.showOverlay = false
			},
		}
	}
</script>

<style scoped>
	input{
		border: 0;
		outline: none;
		background-color: rgba(0,0,0,0);
	}
	.main{
		color:#333333;
		font-size: 15px;
	}
	.info-card-box{
		background: #FFFFFF;
		padding:15px;
	}
	.perf-info-box{
		margin: 10px 0 0 0;
		background: #FFFFFF;
		border-radius: 8px;
		padding:15px;
	}
	.order-status-title{
		font-weight: bold;
		font-size: 16px;
	}
	.ticket-title{
		font-size: 16px;
		margin-bottom: 10px;
	}
	.no-text{
		margin-bottom: 10px;
	}
	.card-line-box{
		display: flex;
		justify-content: flex-start;
		height: 30px;
		line-height: 30px;
	}
	.card-item-title{
		color: #666666 ;
	}
	.card-item-text{
		color: #333333 ;
	}
	.table-title-box{
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.table-title-text{
		padding-left: 5px;
		color: #000000;
		font-size: 16px;
	}
	/*表格开始*/
	.table-box{
		margin: 15px 0;
		border-top: 1px solid #F6F6F6;
		border-left: 1px solid #F6F6F6;
		width: calc(100vw - 30px);
		border-radius: 1px;
	}
	.table-title-line{
		width: 100%;
		display: flex;
		justify-content: space-between;
		background-color: #F1F4FD;
		color: #666666;
		min-height: 42px;
		text-align: center;
		font-size: 14px;
	}
	.table-line{
		width: 100%;
		display: flex;
		justify-content: space-between;
		color: #000000;
		min-height: 42px;
		text-align: center;
	}
	.table-cell{
		display: flex;
		justify-content: center;
		align-items: center;
		border-right: 1px solid #F6F6F6;
		border-bottom: 1px solid #F6F6F6;
		word-wrap: break-word;
		padding: 5px;
		text-align: center;
	}
	/*表格结束*/
	.add-button{
		width: 146px;
		height: 42px;
		background: #5A7DF6;
		border-radius: 4px;
		line-height: 42px;
		color: #ffffff;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 15px;
	}
	.add-button-icon{
		font-size: 28px;
		padding-right: 5px;
	}
	.add-button-text{
		font-size: 17px;
	}
	.bottom-button-box{
		position: fixed;
		bottom: 0;
		width: 100vw;
		display: flex;
		justify-content: space-around;
		padding-bottom: 30px;
		background-color: #ffffff;
	}
	.button-no{
		width: 30vw;
		height: 40px;
		line-height: 40px;
		text-align: center;
		background: #FA7A6F;
		border-radius: 4px;
		color: #ffffff;
	}
	.button-ok{
		width: 30vw;
		height: 40px;
		line-height: 40px;
		text-align: center;
		background: #5A7DF6;
		border-radius: 4px;
		color: #ffffff;
	}
	.steps-title{
		font-size: 15px;
	}
	.leader-box{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		line-height: 30px;
	}
	.leader-item{
		/*padding: 5px 10px;*/
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}
	.leader-item-box{
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
	.leader-item-time{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		text-align: center;
		color: #666666;
	}
	.leader-item-name-box{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		height: 28px;
		line-height: 28px;
		text-align: center;
		border-radius: 2px;
	}
	.leader-item-header-img{
		position: relative;
	}
	.step-ok-img{
		width: 10px;
		height: 10px;
		position: absolute;
		right: 2px;
		top: -3px;
	}
	.step-img{
		width: 20px;
		height: 20px;
		padding-right: 5px;
	}
	/*驳回弹窗*/
	.message-box-info-box{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
	}
	.message-box-text{
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 20px;
	}
	.message-box-button{
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-top: 10px;
	}
	.message-box-info-top-title{
		font-weight: bold;
		text-align: center;
		padding: 20px 0;
	}
	.message-box-info-top-info{
		color: #666666;
		padding: 0 20px 20px 20px;
		text-align: center;
	}
	.button-ok-popup{
		color: #237FF0;
		height: 60px;
		line-height: 60px;
		font-size: 16px;
		text-align: center;
		border-left: 2px solid #F1F1F1;
		border-top: 1px solid #F1F1F1;
		width: 40vw;
	}
	.button-cancel{
		color: #666666;
		height: 60px;
		line-height: 60px;
		font-size: 16px;
		text-align: center;
		border-top: 1px solid #F1F1F1;
		width: 40vw;
	}
	/*驳回弹窗*/
	.add-branch-box{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 10px;
	}
	.select-type-box{
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 2px;
		border: 1px solid #DADADA;
		height: 28px;
		line-height: 28px;
		padding: 0 10px;
	}
	.add-branch-input{
		margin-left: 15px;
		border: 1px solid #DADADA;
		height: 28px;
		line-height: 28px;
		width: 50px;
		padding: 0 10px;
	}
	.picker-box{
		position: fixed;
		bottom: 0;
		width: 100vw;
		z-index: 100;
	}
	.leader-ok{
		/*color: #70e508;*/
		padding-right: 10px;
	}
	.leader-no{
		/*color: #f6404f;*/
		padding-right: 10px;
	}
	.back-msg{
		height: 32px;
		line-height: 32px;
	}
	.active-tip{
		color: #5A7DF6;
		border-radius: 2px;
		border: 1px solid #5A7DF6;
		padding: 2px 5px;
		margin-left: 10px;
	}
	/deep/ .van-uploader__upload{
		background-color: #ffffff !important;
	}
	/deep/ .van-uploader__upload-icon {
		font-size: 80px !important;
		display: flex;
		align-items: center;
	}
	/deep/ .van-step--vertical .van-step__circle-container{
		font-size: 20px !important;
	}
	/deep/ .van-step--vertical .van-step__line{
		top: 30px !important;
		height: 75% !important;
		border-left:1px dashed #ebedf0;
		background-color: #ffffff !important;
	}
</style>
